@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Bangers&display=swap');

.App {
  text-align: center;
}

p {
  font-family: 'Balsamiq Sans', cursive;
}

.beta {
  color: #f50057;
  text-shadow: 1px 1px rgb(63, 63, 63);
  margin-top: 0;
}

.footer {
  position: fixed;
  right: 12px;
  bottom: 0;
  width: 100%;
  color: black;
  text-align: right;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 16px;
  margin-top: -30px;
}

.settingsContainer {
  background-color: #92b2f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
.settingsTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 16;
}

.smallprint {
  font-size: 12px;
  font-style: italic;
  margin-top: 4px;
  margin-bottom: 8px;
  text-shadow: none;
  color:#414142;
}
.settings-heading {
  font-size: 24px;
  color: rgb(255, 255, 255);
  text-shadow: 1px 2px #000;
}

.helper-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 16px;
  max-width: 500px;
  background-color: #B7D100;
  padding: 8px 22px;
  border-radius: 8px;
}

.helper-heading {
  font-size: 16px;
  /* color: #FAF9F6; */
  color: #3f51b5;
  /* text-shadow: 1px 2px #000; */
  margin: 4px;
}
.modal-container {
  height: 100%;
  max-height: 600px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 5%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  border-radius: 10px;
  /* box-shadow: 5px 10px #888888; */
  filter: drop-shadow(0 0 0.35rem rgb(101, 155, 14));
}
@media only screen and (max-width: 820px) {
  .modal-container {
    margin: 8px 8px;
    max-height: 680px;
  }
}

.validation-text {
  color: rgb(228, 83, 83);
  font-size: 12px;
  font-style: italic;
  padding: 8px 0;
  text-align: center;
}

h1 {
  font-size: 38px;
  color: rgb(255, 255, 255);
  text-shadow: 4px 2px #000;
  margin-bottom: 0;
}

p {
  font-size: 20px;
  font-weight: 400;
}

/* Animated BG Main */
.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

/* Sparse animated bg */
.sparseBgContainer {
  width: 100%;
  position: absolute;
  height: 100vh;
}

.area{
  -moz-transition:background-color .2s ease-in;
  -o-transition:background-color .2s ease-in;
  -webkit-transition:background-color .2s ease-in;
  background: #4e54c8;  
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
  width: 100%;
  height:100vh; 
}

@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}